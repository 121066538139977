<template>
  <c-box flexGrow="1" :mx="['20px', null]">
    <h1
      v-chakra
      :px="['20px', null]"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      color="#111111"
      mb="32px"
    >
      Coaching Course
    </h1>
    <c-flex v-if="!isEmpty" flexWrap="wrap">
      <c-button
        v-for="item in category"
        :key="item.value"
        :color="selectedCategory == item.value ? 'brand.900' : '#888888'"
        :border-color="selectedCategory == item.value ? 'brand.900' : '#888888'"
        :variant="selectedCategory == item.value ? 'solid' : 'outline'"
        @click="onSelectCategory(item.value)"
        border="1px"
        :fontSize="['12px', '16px']"
        lineHeight="24px"
        px="26px"
        py="6px"
        borderRadius="36px"
        :mr="['10px', '13px']"
        mb="13px"
      >
        {{ item.label }}
      </c-button>
    </c-flex>

    <EmptyPage v-if="isEmpty" label="Coaching Course tidak tersedia" />

    <c-flex
      v-if="!isEmpty"
      flex="1"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      :my="['30px', '60px']"
      :mb="['100px', null]"
    >
      <c-flex
        v-for="(item, index) in items"
        :key="index"
        w="100%"
        borderBottom="1px solid #F2F2F2"
        :py="[null, '22px']"
        :px="[null, '26px']"
        mb="17px"
        background="#FFFFFF"
        border="1px solid #C4C4C4"
        box-sizing="border-box"
        box-shadow="0px 5px 30px rgba(0, 0, 0, 0.05)"
        border-radius="12px"
        overflow="hidden"
        cursor="pointer"
        :flexDirection="['column', 'row']"
        @click.prevent="
          $router.push({
            name: 'content-challenge.detail',
            params: { id: item.id },
          })
        "
      >
        <c-box
          bg="#C4C4C4"
          d="flex"
          :w="['100%', '96px']"
          :h="['auto', '96px']"
          :mr="[null, '20px']"
        >
          <c-image
            w="100%"
            min-w="96px"
            :borderRadius="[0, '12px']"
            :src="
              item.media ? getPhotoProgram(item.media.url) : getPhotoProgram()
            "
          />
        </c-box>
        <c-box flexGrow="1" :px="['20px', null]" :py="['13px', null]">
          <c-flex justify="space-between">
            <c-heading
              as="h4"
              fontWeight="bold"
              color="black.900"
              :font-size="['14px', '18px']"
              lineHeight="27px"
            >
              {{ item.title }}
            </c-heading>
          </c-flex>
          <c-text
            fontWeight="500"
            color="brand.900"
            fontSize="12px"
            lineHeight="18px"
            pb="6px"
          >
            {{ getLabelCategory(item.category) }}
          </c-text>
          <c-text
            color="black.900"
            :font-size="[null, '14px']"
            :lineHeight="[null, '21px']"
            v-html="item.description"
          />
        </c-box>
      </c-flex>

      <c-box mt="30px" w="fit-content">
        <Pagination
          :total-pages="totalPage"
          :per-page="perPage"
          :max-visible-buttons="3"
          :current-page="parseInt($route.query.page) || 1"
          @pagechanged="$router.push({ query: { page: $event } })"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import EmptyPage from "@/views/content-challenge/empty.vue";
import generalMixin from "@/utils/general-mixins";
import Pagination from '../../components/pagination.vue';

export default {
  name: "CoachingCoursePage",
  components: { EmptyPage, Pagination, },
  mixins: [generalMixin],
  data() {
    return {
      selectedCategory: "",
      page: 1,
      perPage: 10,
    };
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      items: "contentChallenge/items",
      meta: "contentChallenge/meta",
      category: "contentChallenge/categoryCoaching",
    }),
    isEmpty() {
      if (_.isEmpty(this.items) || this.items.length === 0) return true;
      return false;
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10);
    },
    totalPage() {
      let total = this.meta.total ?? 1;
      let perPage = this.perPage
      let length = Math.ceil(total / perPage)
      return length;
    },
    pages() {
      let total = this.meta.total ?? 1;
      let perPage = this.perPage;
      let length = Math.ceil(total / perPage);
      return Array.from({ length }, (_, idx) => idx + 1);
    },
    isNutri() {
      if (this.user && this.user.role == "nutritionist") return true;
      return false;
    },
    isClient() {
      if (this.user && this.user.role == "client") return true;
      return false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1;
        this.selectedCategory = route.query.category
          ? route.query.category
          : "";

        let params = new URLSearchParams();
        params.set("type", "coaching_course");
        params.set("category", this.selectedCategory);
        params.set("page", this.page);
        params.set("pagesize", this.perPage);
        if (this.isClient) {
          this.listDailyCoachingClient(params);
        }
        if (this.isNutri) {
          this.listDailyCoachingNutritionist(params);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      listDailyCoachingClient: "contentChallenge/listContentChallengeClient",
      listDailyCoachingNutritionist:
        "contentChallenge/listContentChallengeNutritionist",
    }),
    onSelectCategory(value) {
      this.page = 1;
      this.selectedCategory = value;
      this.$router.push({
        name: "content-challenge.coaching-course",
        query: {
          category: value,
        },
      });
    },
    getLabelCategory(value) {
      if (value) {
        return this.category.find((dt) => dt.value === value)?.label;
      }
      return "-";
    },
    prevPage() {
      this.page -= 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
    nextPage() {
      this.page += 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
  },
  mounted() {},
};
</script>
